<template>
    
    <div class="oc__figures-container">
        <div :class="['oc__figures',position_box]">
            <div 
            v-for="(item,index) in print_objects"
            :key="item.id"
            class="figure__pos" 
            @click="getKeyAnswer(index,item.clave)"
            >
                <svg-icon 
                    type="mdi" 
                    :path="item.icon"
                    color="tomato"
                    class="cb__icon--size"
                ></svg-icon>
                <div :class="['oc__check-answer',status_answer]" v-if="check_answer&&index==index_answer_selected"></div>
            </div> 
            <div :class="[capa_protectora]" v-if="capa_status"></div>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import {mdiBicycle,mdiAirplane,mdiCarEstate,mdiSailBoat,mdiHumanHandsup,mdiRadio,mdiEmoticonCool,mdiEmoticon,mdiHandWash,mdiPineTree,mdiRocketLaunch } from '@mdi/js'
export default {
    name:'ObjetoCambiaComponent',
    props:['iniciar','tutorialstatus','gameruning'],
    components:{
        SvgIcon
    },
    data:()=>({
        icon:[mdiBicycle,mdiAirplane,mdiCarEstate,mdiSailBoat,mdiHumanHandsup,mdiRadio,mdiEmoticonCool,mdiEmoticon,mdiHandWash,mdiPineTree,mdiRocketLaunch],
        position_box:'oc__figures--initial-position',
        capa_protectora:'',
        print_objects:[],
        status_answer:'',
        check_answer:false,
        index_answer_selected:null,
        capa_staus:false
    }),
    methods:{
        getKeyAnswer(index,clave){
            this.index_answer_selected = index
            this.check_answer = true;
            if(clave)
            {
                this.status_answer = 'oc__correct-answer'
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                this.$parent.puntos = this.$parent.puntos + 100;
                a.play()
            }
            else{
                this.status_answer = 'oc__wrong-answer'
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                this.$parent.puntos = this.$parent.puntos - 30;
                a.play()
            }
            window.setTimeout(()=>{
                this.gameGenerator()
            },1000)
        },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        getArrayRandom(min,max,cantidad){
            //let total = max-min;
            let res = [];
            while(!(res.length==cantidad))
            {
                let aux = this.getRandom(min,max);
                res.push(aux)
            }
            return res;
        },
        delayed(x){
            return new Promise((resolve)=>setTimeout(()=>resolve(x),1000))
        },
        figuresGenerator(){
            this.print_objects=[]
            for(let i=0;i<4;i++)
            {
                this.print_objects.push({
                    icon:this.icon[this.getRandom(0,10)],
                    clave:false
                })
            }
            this.print_objects[this.getRandom(0,3)].clave = true;

        },
        changeFigureRandom(){
            let index_clave = this.print_objects.findIndex(e=>e.clave==true)
            while(index_clave!=4)
            {
                let new_icon = this.icon[this.getRandom(0,10)];
                if(this.print_objects[index_clave].icon !== new_icon)
                {
                    this.print_objects[index_clave].icon = new_icon;
                    break;
                }
            }
        },
        async gameGenerator(){
            this.position_box = ''
            this.check_answer = false
            this.index_answer_selected = null
            this.capa_protectora = 'capa_protectora';
            this.capa_staus = true
            if(!this.tutorialstatus&&this.gameruning)
            {
                this.position_box = 'oc__figures--initial-position'
                
               await this.delayed(5).then(()=>{    
                   this.position_box ='oc__figures--medium-position'
                   this.figuresGenerator()
                   return this.delayed(); 
                }).then(()=>{        
                    this.position_box = 'oc__figures--medium-position'
                    return this.delayed(); 
                }).then(()=>{        
                    this.position_box = 'oc__figures--medium-position'
                    return this.delayed(); 
                }).then(()=>{        
                    this.position_box = 'oc__figures--end-position'
                    
                    return this.delayed(); 
                }).then(()=>{    
                   this.changeFigureRandom()
                   this.position_box ='oc__figures--medium-position'
                   this.capa_protectora = ''
                   this.capa_staus = false
                   return this.delayed(); 
                })
            }
        }
    },
    mounted(){
        this.gameGenerator()
    }
}
</script>

<style>
.oc__figures-container{
    width: 90%;
    height: 10em;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.oc__figures{
    width: 100%;
    height: 8em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: all 1s;
}
.capa_protectora{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: blueviolet;
    opacity: 0;
    top:0px;
    z-index: 9;
}
.figure__pos{
    z-index: 1;
    width: 3em;
    height: 3em;
    padding: 0.5em;
    background-color: rgb(29, 233, 189);
    border-radius: 50%;
    border:solid 3px rgb(18, 129, 105);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
}
.figure__pos:hover{
    transform: translateY(-10%);
}
.oc__figures--initial-position{
    opacity: 0.1;
    transform: translateY(-110%);
}
.oc__figures--medium-position{
    transform: translateY(0%);
    opacity: 1;
}
.oc__figures--end-position{
    transform: translateY(110%);
    opacity: 0.1;
}
.oc__check-answer{
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    right:0px;
    top: 0px;
}
.oc__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.oc__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
@media (min-width: 900px) and (orientation: landscape) {
    .figure__pos{
        width: 5em;
        height: 5em;
    }
}
</style>