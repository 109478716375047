<template>
    <div class="objeto-cambia">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Memoria"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1619824887/juegos-cramer/tutoriales/nivel1/percepcion/memoria_uno_wqcqmy.mp4"
        text="¿Qué objeto cambia?"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="oc__game-box-container">
                <div class="oc__game-box">
                    <objeto-cambia-component 
                    v-if="ejecutar"
                    :inicia="ejecutar" 
                    :tutorialstatus="tutorialStatus"
                    :gameruning="gameRuning"
                    />
                </div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="un objeto cambia" nivel="1" inteligencia="memoria"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import ObjetoCambiaComponent from './ObjetoCambiaComponent'
import {mapState,mapMutations} from 'vuex'

export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,
        ObjetoCambiaComponent
    },
    data:()=>({
        puntos:0,
        show:true,
        serie:[],
        gameRuning:true,
        visibleAnswer:false,
        checkAnswer:'',
        index_answer_selected:0,
        capaProtectora:false,
        position_box:'',
        figures:[],
        print_figures:[],
    }),
    computed:{
        ...mapState(['tutorialStatus']),
        ejecutar(){
            if(!this.tutorialStatus&&this.gameRuning)
            {
                return true;
            }else{
                return false;
            }
        }
    },
    methods:{
         ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
       setGameRuning(){
                this.gameRuning = false
                if(this.puntos<0)
                {
                    this.changeScoreGame(0)
                    this.changeScoreDescription('insuficiente')
                }
                else{
                    this.changeScoreGame(this.puntos)
                    if(this.puntos<=200){
                    this.changeScoreDescription('insuficiente')
                    }
                    else if(this.puntos<=400){
                        this.changeScoreDescription('escasa')
                    }
                    else if(this.puntos<=600){
                        this.changeScoreDescription('suficiente')
                    }
                    else if(this.puntos<=800){
                        this.changeScoreDescription('buena')
                    }
                    else{
                        this.changeScoreDescription('optima')
                    }
                }
            },
    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.changeTutorialStatus(true)
    },
}
</script>
<style>
.objeto-cambia{
    width: 100%;
    min-height: 100vh;
    background-color: #1F0275;
}
.oc__game-box-container{
    width: 100%;
    margin-top:2em;
    display: flex;
    justify-content: center;
}
.oc__game-box{
    width: 90%;
    height: 53vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}

.oc__active-animation{
    animation-name: entrada;
    animation-play-state: running;
    animation-timing-function: ease;
    animation-duration: 2s;
    animation-iteration-count: 1;
}
.oc__figure{
    width: 3em;
    height: 3em;
    padding: 5px;
    border:solid 2px #434343;
    border-radius: 50%;
    background-color: rgb(46, 168, 168);
}
.bounce-enter-active {
  animation: bounce-in 2s reverse;
}
.bounce-leave-active {
  animation: bounce-in 2s reverse;
}
@keyframes entrada {
  0% {
    transform: translateX(0%);
  }
  30% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.oc__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.oc__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
@media (min-width: 900px) and (orientation: landscape) {
    .oc__game-box{
        width: 60%;
    }   
}
</style>